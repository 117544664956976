<template>
  <v-select
    :id="id"
    ref="category-select"
    v-model="model"
    :options="options"
    :reduce="reduce"
    :filterable="false"
    label="name"
    placeholder="Selecciona una categoría"
    transition=""
    @search="handleSearch"
    @option:selected="$emit('option-selected', $event)"
  >
    <template #option="{ name, index }">
      <div :class="'ml-'+ (index)">
        {{ index > 0 ? '- ' + name : name }}
      </div>
    </template>
  </v-select>
</template>

<script>
import CategoriesApi from '@/api/categories-api'

export default {
  name: 'CategorySelect',
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    reduce: {
      type: Function,
      default: item => item,
    },
  },
  data() {
    return {
      categories: [],
      searchTerm: null,
      searchTimeout: null,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
    options() {
      if (this.categories.length === 0) {
        return []
      }

      return this.categories.reduce((result, category) => result.concat(this.flattenCategory(category, 0)), [])
    },
  },
  mounted() {
    this.addClearButtonEvent()
    this.loadCategories()
  },
  methods: {
    addClearButtonEvent() {
      const clearButton = this.$refs['category-select'].$children[0].$el
      clearButton.addEventListener('click', () => this.$emit('clear'), false)
    },
    async loadCategories() {
      try {
        const response = await CategoriesApi.list({ filters: { search: this.searchTerm } })
        this.categories = response.data
      } catch (error) {
        console.error(error)
      }
    },
    flattenCategory(category, index) {
      if (!category.chields || category.chields.length === 0) {
        return [{ ...category, index }]
      }

      let categories = [{ ...category, index }]
      category.chields.forEach(categoryChild => {
        categories = categories.concat(this.flattenCategory(categoryChild, index + 1))
      })

      return categories
    },
    handleSearch(searchTerm) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }

      this.searchTimeout = setTimeout(() => {
        this.searchTerm = searchTerm
        this.loadCategories()
      }, 500)
    },
  },
}
</script>

<style scoped>

</style>
