<template>
  <validation-observer ref="create-category">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col cols="6">
          <validation-provider
            v-slot="validationContext"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              label="Nombre"
              label-for="category-name"
            >
              <b-form-input
                v-model="form.name"
                name="category-name"
                placeholder="Nombre"
                :state="$getValidationState(validationContext)"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Descripción"
            label-for="category-description"
          >
            <b-form-input
              v-model="form.description"
              name="category-description"
              placeholder="Descripción"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Categoría padre"
            label-for="category-parent"
          >
            <CategorySelect
              id="category-parent"
              v-model="form.parent"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Grupo de categorías"
            label-for="category-group"
          >
            <CategoryGroupSelect
              id="category-group"
              v-model="form.categoryGroup"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Referencia"
            label-for="category-reference"
          >
            <b-form-input
              v-model="form.reference"
              name="category-reference"
              placeholder="Referencia"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import CategorySelect from '@/components/category/select/CategorySelect.vue'
import CategoryGroupSelect from '@/components/category/select/CategoryGroupSelect.vue'

export default {
  name: 'CategoryForm',
  components: { CategoryGroupSelect, CategorySelect },
  props: {
    category: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        name: null,
        description: null,
        parent: null,
        categoryGroup: null,
        reference: null,
      },
    }
  },
  watch: {
    category: {
      deep: true,
      handler() {
        this.populateForm()
      },
    },
  },
  mounted() {
    this.populateForm()
  },
  methods: {
    populateForm() {
      if (!this.category) {
        return
      }

      this.form.name = this.category.name
      this.form.description = this.category.description
      this.form.categoryGroup = this.category.category_group
      this.form.parent = this.category.parent
      this.form.reference = this.category.reference
    },
    submitForm() {
      return new Promise(resolve => this.$refs['create-category'].validate().then(async success => resolve({
        success,
        category: {
          name: this.form.name,
          description: this.form.description,
          category_group_id: this.form.categoryGroup?.id,
          parent_id: this.form.parent?.id,
          reference: this.form.reference,
        },
      })))
    },
  },
}
</script>

<style scoped>
</style>
