<template>
  <div>
    <BaseCard
      title="datos de la categoría"
      :footer-visible="false"
      :loading="loading"
    >
      <CategoryForm
        ref="category-form"
        :category="category"
      />
    </BaseCard>
    <CategoryAttributesList :category-id="categoryId" />
    <b-row
      align-v="center"
      align-h="end"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
          :disabled="loading"
          @click="handleSaveButtonClick"
        >
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CategoriesApi from '@/api/categories-api'
import CategoryForm from '@/components/category/form/CategoryForm.vue'
import CategoryAttributesList from '@/components/category/list/CategoryAttributesList.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    CategoryAttributesList,
    CategoryForm,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('category', {
      category: 'getCategory',
    }),
    categoryId() {
      return parseInt(this.$route.params?.id, 10) || null
    },
  },
  mounted() {
    this.fetchCategory()
  },
  methods: {
    ...mapActions('category', ['loadCategory']),
    async fetchCategory() {
      this.loading = true
      try {
        await this.loadCategory(this.$route.params.id)
      } catch (error) {
        console.error(error)
        this.$toast.error('Ocurrió un error al actualizar la categoría. Por favor, inténtelo de nuevo mas tarde.')
      }
      this.loading = false
    },
    async handleSaveButtonClick() {
      const formData = await this.$refs['category-form'].submitForm()

      if (!formData.success) {
        return
      }

      await this.editCategory(formData.category)
    },
    async editCategory(category) {
      this.loading = true
      try {
        await CategoriesApi.edit(this.$route.params.id, category)
        await this.$router.go(-1)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
